<template>
  <div>
    <iframe :src="video.filename" frameborder="0"></iframe>
    <caption></caption>
  </div>
</template>

<script>
export default {
  name: "VideoThumb",

  props: {
    video: {
      type: Object,
      required: true,
    }
  },

  setup() {
    // const getThumbUrl = (filename) => {
    //   console.log(filename)
    //   return require(`../assets/images/${filename}`);
    // }

    // return { getThumbUrl }
  },
}
</script>

<style lang="scss" scoped>
</style>
