<template>
  <div id="gallery" class="content content--gallery">
    <h1>Gallery</h1>

    <ul v-if="albumList.length > 1">
      <li v-for="album in albumList" :key="album">
        <a :href="`#` + toKebab(album)">{{ album }}</a>
      </li>
    </ul>

    <div>
      <div
        :id="toKebab(album.album)"
        class="album"
        v-for="album in filteredMedia"
        :key="album.album"
      >
        <h2 v-if="albumList.length > 1">{{ album.album }}</h2>

        <div class="gallery">
          <div class="gallery-panel" v-for="item in album.media" :key="item.id">
            <ImageThumb v-if="item.type === 'image'" :image="item" />
            <VideoThumb v-else-if="item.type === 'video'" :video="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// https://codepen.io/iamsaief/pen/jObaoKo
import { computed } from "@vue/runtime-core";
import media from "@/photos.json";
import ImageThumb from "./ImageThumb";
import VideoThumb from "./VideoThumb";
import _ from "lodash";

export default {
  name: "GalleryGrid",

  components: {
    ImageThumb,
    VideoThumb,
  },

  props: {},

  setup() {
    let albumList = [];

    const filteredMedia = computed(() => {
      return _.chain(media)
        .groupBy("album")
        .map((value, key) => ({ album: key, media: value }))
        .value();
    });

    const toKebab = (text) => {
      return _.kebabCase(text);
    };

    albumList = computed(() => {
      return _.uniq(_.map(media, "album"));
    });

    return { toKebab, albumList, filteredMedia };
  },
};
</script>

<style lang="scss">
.gallery {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  margin: 1rem auto;
  max-width: 80rem;
  // padding: 0 5rem;
}

.gallery-panel img {
  height: 15rem;
  object-fit: cover;
  width: 100%;
}
</style>
