<template>
  <router-link :to="`/gallery/media/${image.id}`">
    <!-- <img v-lazy="{ src: imageSrc }" :alt="image.alt" /> -->
    <img v-lazy="imageSrc" :alt="image.alt" />
  </router-link>
</template>

<script>
import { computed } from "@vue/runtime-dom"

export default {
  name: "ImageThumb",

  props: {
    image: {
      type: Object,
      required: true,
    },
  },

  setup( props ) {
    const imageSrc = computed(() => {
      return props.image.filename.slice(0,58) + "x25" + props.image.filename.slice(57);
    })

    return { imageSrc }
  },
};
</script>

<style lang="scss" scoped>
</style>
